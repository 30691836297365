
  import { deepCopy, localToUtc } from '@/utils/general'
  import dayjs from 'dayjs'
  import { Component } from 'vue-property-decorator'
  import SupervisorDashboard from './SupervisorDashboard'

@Component
  export default class SupervisorInspections extends SupervisorDashboard {
    // Methods
    beforeCreate () {
      this.getData = async () => {
        this.$set(this.chartData.currentMonth, 'inspections', await this.getInspections(this.currentMonthFilters.startEndDate))
        this.$set(this.chartData.prevMonth, 'inspections', await this.getInspections(this.prevMonthFilters.startEndDate))
      }
    }

    removeOtherStates (records) {
      return this.normaliceData(records)
        .filter(record => record.closingReason?.type?.name !== 'expired')
        .filter(record => !record.inspectorQualification)
    }

    sortByScheduled (records) {
      return records.sort((prev, next) => {
        const prevDate = dayjs(prev.scheduledDate)
        const nextDate = dayjs(next.scheduledDate)

        if (prevDate.isBefore(nextDate)) {
          return 1
        } else if (prevDate.isAfter(nextDate)) {
          return -1
        }
        return 0
      })
    }

    normaliceData (records) {
      return records.map(record => {
        const {
          executive,
          executiveId,
          autoDescription,
          year,
          inspector,
          inspectorId,
          closingReason,
          status,
          scheduledDate,
          closingReasonDate,
        } = record
        const attributes = [
          'executive',
          'executiveId',
          'autoDescription',
          'year',
          'inspector',
          'inspectorId',
          'status',
          'scheduledDate',
          'closingReasonDate',
        ]
        const corrected = deepCopy(record)
        attributes.forEach(attribute => delete corrected[attribute])
        return {
          ...corrected,
          car: `${autoDescription} ${year}`,
          executive: {
            id: executiveId,
            name: executive?.toUpperCase() || 'SIN EJECUTIVO',
            scheduledDate: dayjs(localToUtc(scheduledDate)).format('DD/MM/YY - HH:mm'),
          },
          inspector: {
            id: inspectorId,
            name: inspector?.toUpperCase() || 'SIN INSPECTOR',
            closingReasonDate: dayjs(localToUtc(closingReasonDate)).format('DD/MM/YY - HH:mm'),
          },
          status: closingReason || status,
        }
      })
    }

  // Getters

  // Watchers
  }
