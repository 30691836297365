var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"mt-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('dashboard-card',{attrs:{"title":"Acumulado de inspecciones"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart-by-criteria',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQueries([
                {category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.currentMonthFilters.inspections},
                {category: 'prevMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.prevMonthFilters.inspections},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQueries([\n                {category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: currentMonthFilters.inspections},\n                {category: 'prevMonth', field: 'inspections', queryMethod: getInspections, queryFilter: prevMonthFilters.inspections},\n              ])\n            }"}],attrs:{"current-month-records":_vm.chartData.currentMonth.inspections,"prev-month-records":_vm.chartData.prevMonth.inspections,"criterias":[
              {
                name: 'Agendada',
                color: '#FAD901',
                filter: (record) => true,
              },
              {
                name: 'Cancelada',
                color: '#F03738',
                filter: (record) => record.closingReason?.description === 'Cancelada',
              },
              {
                name: 'Finalizada',
                color: '#13CE66',
                filter: (record) => Boolean(record.inspectorQualification),
              },
              {
                name: 'Validada',
                color: '#7D67BC',
                filter: (record) => Boolean(record.supervisorQualification),
              },
              {
                name: 'Compradas',
                color: '#6185DB',
                filter: (record) => Boolean(record.bought),
              },
            ],"path-to-date":['createdAt'],"data-transformer":_vm.normaliceData,"type":"line","accumulated":true,"dates":_vm.dates,"show-total":false,"sort-legends":false}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Agendadas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.currentMonthFilters.inspections})}),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: currentMonthFilters.inspections})}"}],attrs:{"records":_vm.chartData.currentMonth.inspections,"records-settings":_vm.chartData.settings.inspection,"target-field":"quantity","executives":_vm.chartData.settings.executives,"icon":"mdi-car-search","data-transformer":_vm.normaliceData,"path-to-details":[
              {
                name: 'Ejecutivo',
                path: ['executive', 'name'],
                pathToComplement: ['executive', 'scheduledDate'],
              },
              {
                name: 'Inspector',
                path: ['inspector', 'name'],
                pathToComplement: ['inspector', 'closingReasonDate'],
              }
            ],"path-to-title":['car'],"path-to-subtitle":['registrationPlate'],"path-to-color":['status', 'color'],"sorting-method":_vm.sortByScheduled}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Canceladas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.currentMonthFilters.inspections})}),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: currentMonthFilters.inspections})}"}],attrs:{"records":_vm.chartData.currentMonth.inspections,"records-settings":_vm.chartData.settings.inspection,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceData,"path-to-details":[
              {
                name: 'Ejecutivo',
                path: ['executive', 'name'],
                pathToComplement: ['executive', 'scheduledDate'],
              },
              {
                name: 'Inspector',
                path: ['inspector', 'name'],
                pathToComplement: ['inspector', 'closingReasonDate'],
              }
            ],"path-to-title":['car'],"path-to-subtitle":['registrationPlate'],"path-to-color":['status', 'color'],"filtering-criteria":(record) => record.closingReason?.description === 'Cancelada',"use-total-as-target":true,"sorting-method":_vm.sortByScheduled}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Vencidas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.currentMonthFilters.inspections})}),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: currentMonthFilters.inspections})}"}],attrs:{"records":_vm.chartData.currentMonth.inspections,"records-settings":_vm.chartData.settings.inspection,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceData,"path-to-details":[
              {
                name: 'Ejecutivo',
                path: ['executive', 'name'],
                pathToComplement: ['executive', 'scheduledDate'],
              },
              {
                name: 'Inspector',
                path: ['inspector', 'name'],
                pathToComplement: ['inspector', 'closingReasonDate'],
              }
            ],"path-to-title":['car'],"path-to-subtitle":['registrationPlate'],"filtering-criteria":(record) => record.closingReason?.type?.name === 'expired',"use-total-as-target":true,"sorting-method":_vm.sortByScheduled}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Finalizadas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.currentMonthFilters.inspections})}),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: currentMonthFilters.inspections})}"}],attrs:{"records":_vm.chartData.currentMonth.inspections,"records-settings":_vm.chartData.settings.inspection,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceData,"path-to-details":[
              {
                name: 'Ejecutivo',
                path: ['executive', 'name'],
                pathToComplement: ['executive', 'scheduledDate'],
              },
              {
                name: 'Inspector',
                path: ['inspector', 'name'],
                pathToComplement: ['inspector', 'closingReasonDate'],
              }
            ],"path-to-title":['car'],"path-to-subtitle":['registrationPlate'],"filtering-criteria":(record) => Boolean(record.inspectorQualification),"use-total-as-target":true,"sorting-method":_vm.sortByScheduled}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Agendadas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.currentMonthFilters.inspections})}),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: currentMonthFilters.inspections})}"}],attrs:{"records":_vm.chartData.currentMonth.inspections,"records-settings":_vm.chartData.settings.inspection,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceData,"path-to-category":['executive'],"path-to-category-title":['name'],"category-name":"ejecutivo"}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Canceladas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.currentMonthFilters.inspections})}),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: currentMonthFilters.inspections})}"}],attrs:{"records":_vm.chartData.currentMonth.inspections,"records-settings":_vm.chartData.settings.inspection,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceData,"path-to-category":['executive'],"path-to-category-title":['name'],"filtering-criteria":(record) => record.closingReason?.description === 'Cancelada',"use-total-as-target":true,"category-name":"ejecutivo"}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Vencidas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.currentMonthFilters.inspections})}),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: currentMonthFilters.inspections})}"}],attrs:{"records":_vm.chartData.currentMonth.inspections,"records-settings":_vm.chartData.settings.inspection,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceData,"path-to-category":['executive'],"path-to-category-title":['name'],"filtering-criteria":(record) => record.closingReason?.type?.name === 'expired',"use-total-as-target":true}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Finalizadas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: _vm.getInspections, queryFilter: _vm.currentMonthFilters.inspections})}),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'inspections', queryMethod: getInspections, queryFilter: currentMonthFilters.inspections})}"}],attrs:{"records":_vm.chartData.currentMonth.inspections,"records-settings":_vm.chartData.settings.inspection,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceData,"path-to-category":['executive'],"path-to-category-title":['name'],"filtering-criteria":(record) => Boolean(record.inspectorQualification),"use-total-as-target":true,"category-name":"ejecutivo"}})]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }